import { useEffect, useState, useRef } from 'react';

const useInView = (ref) => {
  const [isInView, setIsInView] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsInView(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);
    
    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isInView;
}

export default useInView;