import LighterLogo from '../assets/img/LIGHTer_logo.svg';
// import { useState } from 'react';
import { useRef } from 'react';
// import { useEffect } from 'react';

// i18
import i18next from 'i18next';
import '../i18n';

const Footer = ({ scrollToTop }) => {
	const article = i18next.t('footer', { returnObjects: true });
	const component = useRef(null);

	// logos
	const logo1_name = article.collab_img1;
	const logo1 = require(`../assets/img/partners/${logo1_name}`);
	const logo2_name = article.collab_img2;
	const logo2 = require(`../assets/img/partners/${logo2_name}`);

	const itemClick = () => {
		scrollToTop.current.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<div className="footer" ref={component}>
			
			<div className="info_container">
				<div className="grid">
				
					<div className="col col1">
						<div className="menuItemLogo" onClick={itemClick}>
							<img src={LighterLogo} alt="Logo" />
						</div>
					</div>

					<div className="col col2">
						<ul>
							<li>
								<span>{article.address_row1}</span>
							</li>
							<li>
								<span>{article.address_row2}</span>
							</li>
							<li>
								<span>{article.address_row3}</span>
							</li>
							<li>
								<span>{article.address_row4}</span>
							</li>
						</ul>
					</div>

					<div className="col col3">
						<ul>
							<li>
								<a rel="noreferrer" target="_blank" href="mailto:{article.contact_mail}">{article.contact_mail}</a>
							</li>
							<li>
								<a rel="noreferrer" target="_blank" href="https://lighter.nu/sv">LIGHTer.nu</a>
							</li>
						</ul>
						
					</div>

					<div className="col col5">
						<div className="agenda_download">
							{/* <h5>{article.old_agenda_header}</h5> */}
							<p>{article.old_agenda_info}</p>
							<ul>
								<li>
									<a rel="noreferrer" target="_blank" href={article.download_agenda_2019}>{article.title_agenda_2019}</a>
								</li>
								<li>
									<a rel="noreferrer" target="_blank" href={article.download_agenda_2016}>{article.title_agenda_2016}</a>
								</li>
								<li>
									<a rel="noreferrer" target="_blank" href={article.download_agenda_2013}>{article.title_agenda_2013}</a>
								</li>
							</ul>

						</div>
					</div>

					<div className="col col4">
						<div className="logo_grid">

							<div className="logo_col">
								<p className="footer_powered_by">{article.collab_link1_text}</p>
								<a href={`https://www.${article.collab_link1}`} target="_blank" rel="noreferrer">
									<img id="rise_logo" src={logo1} alt="Logo 1" width="62" />
								</a>
							</div>

						</div>
					</div>

					{/* <div className="col col6">
						<div className="agenda_download">
							<p>{article.print_agenda_info}</p>
							<ul>
								<li>
									<a rel="noreferrer" target="_blank" href={article.print_agenda_link}>{article.print_agenda_title}</a>
								</li>
							</ul>

						</div>
					</div> */}

				</div>
			</div>

			<div className="partners_container">
				<div className="grid partners_grid">

					{/* <div className="col col4">
						<div className="logo_grid">

							<div className="logo_col">
								<p className="footer_powered_by">{article.collab_link1_text}</p>
								<a href={`https://www.${article.collab_link1}`} target="_blank" rel="noreferrer">
									<img id="rise_logo" src={logo1} alt="Logo 1" width="62" />
								</a>
							</div>

						</div>
					</div> */}

					<img id="partner_logo" src={logo2} alt="Logo 2" />
				</div>
			</div>

		</div>
	);
}
 
export default Footer;