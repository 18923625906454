import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import Accordion from './Accordion';
import {SplitText} from '../assets/js/SplitText.js';
import { Parallax, Background } from 'react-parallax';
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

const ArticleTextOneCol = ({ textObject, bgColor, showReadmore}) => {
	const ref = useRef();
	const q = gsap.utils.selector(ref);

	const article = i18next.t(textObject, { returnObjects: true });
	
	// background color
	const bgColorCss = { 'backgroundColor': bgColor }

	// bgparallax
	const parallax_image = article.bg_prallax_image;
	const bgparallax = require(`../assets/img/bgs/${parallax_image}`);

	// purifier
	function createMarkup(text) {
		var clean_html = DOMPurify.sanitize(text);
		return {__html: clean_html};
	}

	// show read more


	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM('05_wheel_intro')
			
			// header_bold
			const header_bold = new SplitText(q(".article_header_bold"), { type: "words" })
			gsap.set(header_bold.words, { opacity: 0, x: '-=50px' })
			for (let i = 0; i < header_bold.words.length; i++) {
				gsap.to(header_bold.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05)
				})
			}

			// header_light
			const header_light = new SplitText(q(".article_header_light"), { type: "words" })
			gsap.set(header_light.words, { opacity: 0, x: '-=50px' })
			for (let i = 0; i < header_light.words.length; i++) {
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.5 + (i * 0.05)
				})
			}

			// text + accordion button
			const restElements = [q(".text_content_before"), q(".accordion_container")];
			gsap.set(restElements, { opacity: 0, y: '+=50px' })

			for (let i = 0; i < restElements.length; i++) {
				gsap.to(restElements[i], {
					duration: 1.2,
					x: '0px',
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 1.0 + (i * 0.2)
				})
			}

			gsap.set([q(".header_bold"), q(".header_light")], { opacity: 1 }); // parent css
			// gsap.set([q(".header_bold"), q(".header_light"), q(".accordion_container")], { opacity: 1 }); // parent css
		}

	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<div>
			<section ref={setRefs} className="article article_container ArticleTextOneCol" style={bgColorCss}>
				<Parallax strength={200}>

				<div className="article_inner">

					<div className="">
						<div className="text_content">
							<h3 className="article_header">
								<span className="header_bold article_header_bold">{article.header_bold}</span>
								<span className="header_light article_header_light">{article.header_light}</span>
							</h3>

							<div className="text_content_before" dangerouslySetInnerHTML={createMarkup(article.text_before_accordion)} />
							
							<div className={'accordion_container' + (showReadmore ? ' showReadmore' : ' hideReadmore')}>
								<Accordion text={article.text_after_accordion} />
							</div>

						</div>
					</div>

				</div>
				
				<Background>
					<img src={bgparallax} alt="En bakgrundsbild" />
				</Background>

				<div className="gradient"></div>
			</Parallax>
			</section>
		</div>

	);
}

export default ArticleTextOneCol;