import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import Accordion from './Accordion';

import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const ColumnsText = ({textObject}) => {
	const article = i18next.t(textObject, { returnObjects: true });
	
	// set class for gsap
 	const ref = useRef();
	const q = gsap.utils.selector(ref);

	// purifier
	function createMarkup(text) {
		var clean_html = DOMPurify.sanitize(text);
		return {__html: clean_html};
	} 
 
	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM('02_about_lighter')

			gsap.set(q(".text_header"), { opacity: 0, x: '-=50px' })
			gsap.set([q(".text_subheader"), q(".text_text"), q(".accordion_container")], { opacity: 0, y: '+=50px' })
			// gsap.set(q(".content"), { opacity: 1 }); // parent css

			// header
			gsap.to(q(".text_header1"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.3
			})

			/* gsap.to(q(".text_header2"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.5
			}) */

			// subheader

			gsap.to(q(".text_subheader1"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.3 + 0.3
			})

			/* gsap.to(q(".text_subheader2"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.5 + 0.3
			}) */

			// text

			gsap.to(q(".text_text1"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.3 + 0.5
			})

			/* gsap.to(q(".text_text2"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.5 + 0.5
			}) */

			// accordion

			gsap.to(q(".columns_text_accordion_container1"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 0.5 + 0.5
			})
			
			gsap.set(".lighter_button", { opacity: 0, y: '+=30px' }); // parent css
			gsap.to(q(".lighter_button"), {
				duration: 0.7,
				opacity: 1,
				x: '0px',
				y: '=0px',
				ease: 'power2.out',
				delay: 1.3
			})
		}		
	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<section className="columns_text" ref={setRefs}>
			
			<div className='content_single_col'>
				<h2 className='text_header text_header1'>{article.col1_header}</h2>
				<p className='text_subheader text_subheader1'>{article.col1_subheader}</p>
				<p className='text_text text_text1' dangerouslySetInnerHTML={createMarkup(article.col1_text)}></p>

				<div className="accordion_container columns_text_accordion_container1">
					<Accordion text={article.col1_accordion} />
				</div>

				<div className="lighter_button"><a rel="noreferrer" href={article.button_link} target="_blank">{article.button_text}</a></div>
			</div>
			
		</section>
	);
}




export default ColumnsText;