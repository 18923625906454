
import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

const CircleGraph = () => {

	const article = i18next.t('circle_graph', { returnObjects: true });
	const ref = useRef();

	// const component = useRef(null);
	const q = gsap.utils.selector(ref);

	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);
			
			// push google analytics
			pushGTM('12_circle_numbers')

			// chart values
			var percentage1 = 100;
			var percentage2 = 100;
			var percentage3 = 100;
			var total = 440;
			
			var result1 = ((percentage1 * total) / 100);
			var result2 = ((percentage2 * total) / 100);
			var result3 = ((percentage3 * total) / 100);
			
			const pie_piece1 = document.getElementById('pie_piece1');
			const pie_piece2 = document.getElementById('pie_piece2');
			const pie_piece3 = document.getElementById('pie_piece3');
			
			pie_piece1.style.strokeDasharray = result1;
			pie_piece2.style.strokeDasharray = result2;
			pie_piece3.style.strokeDasharray = result3;

			// counter
			var count1 = {score1:0};
			var count2 = {score2:0};
			var count3 = {score3:0};

			var graph_value1 = 309;
			var graph_value2 = 100;
			var graph_value3 = 300;

			var scoreDisplay1 = document.getElementById("score1");
			var scoreDisplay2 = document.getElementById("score2");
			var scoreDisplay3 = document.getElementById("score3");
			
			gsap.to(count1, {duration: 2, delay: 0, score1:graph_value1, roundProps:"score1", onUpdate:updateHandler1, ease: 'power2.out',});
			gsap.to(count2, {duration: 2, delay: 0, score2:graph_value2, roundProps:"score2", onUpdate:updateHandler2, ease: 'power2.out',});
			gsap.to(count3, {duration: 2, delay: 0, score3:graph_value3, roundProps:"score3", onUpdate:updateHandler3, ease: 'power2.out',});
				
			function updateHandler1() {
				scoreDisplay1.innerHTML = count1.score1;
			}
			function updateHandler2() {
				scoreDisplay2.innerHTML = count2.score2;
			}
			function updateHandler3() {
				scoreDisplay3.innerHTML = count3.score3;
			}

			// disclamber
			gsap.set(q(".disclamber"), { opacity:0 });
			gsap.to(q(".disclamber"), {
				duration: 1,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				ease: 'power2.out',
				delay: 2
			})

		}
		
	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<section className="section_circle_graph" ref={setRefs}>
			<div className="fullwidth_container">
				
				<div className="graph_columns">

					{/* graph 1 */}
					<div className="circle_graph circle_graph1">
						<div className="graph_content">
							<div className="graph_info">
								<div className="score_container"><div className="score" id="score1">0</div>{article.graph1_value_type}</div>
								<div className="text">{article.graph1_text}</div>
							</div>

							<svg width="280" height="280" className="pie_chart" id="pie_chart1">
								<circle r="70" cx="140" cy="140" className="pie_piece" id="pie_piece1" />
							</svg>
						</div>
					</div>

					{/* graph 2 */}
					<div className="circle_graph">
						<div className="graph_content">
							<div className="graph_info">
								<div className="score_container"><div className="score" id="score2">0</div>{article.graph2_value_type}</div>
								<div className="text">{article.graph2_text}</div>
							</div>

							<svg width="280" height="280" className="pie_chart" id="pie_chart2">
								<circle r="70" cx="140" cy="140" className="pie_piece" id="pie_piece2" />
							</svg>
						</div>
					</div>
	
					{/* graph 3 */}
					<div className="circle_graph">
						<div className="graph_content">
							<div className="graph_info">
								<div className="score_container"><div className="score" id="score3">0</div>{article.graph3_value_type}</div>
								<div className="text">{article.graph3_text}</div>
							</div>

							<svg width="280" height="280" className="pie_chart" id="pie_chart3">
								<circle r="70" cx="140" cy="140" className="pie_piece" id="pie_piece3" />
							</svg>
						</div>
					</div>


				</div>
				<div className="disclamber">{article.disclamber}</div>
			</div>


			{/* <svg width="100" height="100" className="pie_chart" id="pie_chart2">
				<circle r="25" cx="50" cy="50" className="pie_piece" id="pie_piece2" />
			</svg> */}

		</section>

	);
}
 
export default CircleGraph;