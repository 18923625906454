import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import {SplitText} from '../assets/js/SplitText.js';
import Accordion from './Accordion';

import { Parallax, Background } from 'react-parallax';
import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const Recommend = ({ textObject, bgColor}) => {
	const ref = useRef();
	const q = gsap.utils.selector(ref);

	const article = i18next.t(textObject, { returnObjects: true });

	// bgparallax
	const parallax_image = article.bg_prallax_image;
	const bgparallax = require(`../assets/img/bgs/${parallax_image}`);

	// background color
	const bgColorCss = { 'backgroundColor': bgColor }

	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM('07_recommend')
			
			// header_bold
			const header = new SplitText(q(".recommend_header"), { type: "words" })
			gsap.set(header.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".recommend_header"), { opacity: 1 })
			for (let i = 0; i < header.words.length; i++) {
				gsap.to(header.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05)
				})
			}

			// intro text
			gsap.set(q(".recommend_text_intro"), { opacity: 0, y: '+=50px' })

			gsap.to(".recommend_text_intro", {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 1.0
			})

			// intro accordion
			gsap.set(q(".accordion_container_intro"), { opacity: 0, y: '+=50px' })

			gsap.to(".accordion_container_intro", {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 1.2
			})

			// numbers
			// recommend_number1
			gsap.set([q(".recommend_number1"), q(".recommend_number2"), q(".recommend_number3")], { opacity: 0, scale: 0 })
			for (let i = 0; i < 3; i++) {
				gsap.to(q(".recommend_number" + (i + 1)), {
					duration: 0.4,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1.0,
					ease: 'back.out(1.7)',
					delay: 1.7 + (i * 0.4)
				})

				gsap.to(q(".recommend_number" + (i + 1)), {
					duration: 0.2,
					opacity: 0.2,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'none',
					delay: 2.1 + (i * 0.4)
				})
			}

			// number header text + accordion button
			gsap.set([q(".recommend_text1"), q(".recommend_text2"), q(".recommend_text3")], { opacity: 0, scale: 0 })

			for (let i = 0; i < 3; i++) {
				gsap.to(q(".recommend_text" + (i + 1)), {
					duration: 0.4,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1.0,
					ease: 'back.out(2.7)',
					delay: 2.3 + (i * 0.4)
				})
			}
			
			// 3 accordions - bug fix
			gsap.set([q(".recommend_acc_1"), q(".recommend_acc_2"), q(".recommend_acc_3")], { opacity: 0, y: '+=50px' })

			gsap.to(q(".recommend_acc_1"), {
				duration: 0.4,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1.0,
				ease: 'power2.out',
				delay: 2.7 + 0.0
			})

			gsap.to(q(".recommend_acc_2"), {
				duration: 0.4,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1.0,
				ease: 'power2.out',
				delay: 2.7 + 0.4
			})

			gsap.to(q(".recommend_acc_3"), {
				duration: 0.4,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1.0,
				ease: 'power2.out',
				delay: 2.7 + 0.8
			})

		}

	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<section ref={setRefs} className="recommend_container" style={bgColorCss}>
			<Parallax strength={200}>

			<div className="article_inner">
				<h2 className="recommend_header">{article.recommend_header}</h2>
				<p className="recommend_text_intro">{article.recommend_text_intro}</p>
				
				<div className="accordion_container accordion_container_intro">
					<Accordion text={article.recommend_text_intro_acc} />
				</div>

				<div className="grid">
				<div className="col col1">

						<div className="number_square">
							<div className="recommend_number recommend_number1">1</div>
							<p className="recommend_text recommend_text1">{article.recommend_text1}</p>
						</div>

						<div className="accordion_container accordion_container1 recommend_acc_1">
							<Accordion text={article.recommend_acc_text1} />
						</div>

					</div>

					<div className="col col2">
						<div className="number_square">
							<div className="recommend_number recommend_number2">2</div>
							<p className="recommend_text recommend_text2">{article.recommend_text2}</p>
						</div>
						
						<div className="accordion_container accordion_container2 recommend_acc_2">
							<Accordion text={article.recommend_acc_text2} />
						</div>

					</div>

					<div className="col col3">
						<div className="number_square">
							<div className="recommend_number recommend_number3">3</div>
							<p className="recommend_text recommend_text3">{article.recommend_text3}</p>
						</div>

						<div className="accordion_container accordion_container3 recommend_acc_3">
								<Accordion text={article.recommend_acc_text3} />
						</div>
					</div>

				</div>
			</div>

			<Background>
				<img src={bgparallax} alt="En bakgrundsbild" />
			</Background>
			</Parallax>
			</section>

	);
}




export default Recommend;