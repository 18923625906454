import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import {SplitText} from '../assets/js/SplitText.js';
import DOMPurify from 'dompurify';
import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import play_btn_img from '../assets/img/structure/play_btn.svg';
import poster from '../assets/video/video_poster.jpg';
import img16x9 from '../assets/video/16x9.png';
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const Video = ({lang}) => {
 	const article = i18next.t('video', { returnObjects: true });
	const ref = useRef();
	const q = gsap.utils.selector(ref);
	const videoRef = useRef();
	const video_src_name = article.video_src;
	const [video_src, setVideoSrc] = useState(null);
	const [videoOverlayStyle, setVideoOverlayStyle] = useState({'display': 'flex'});

	// purifier
	function createMarkup(text) {
		var clean_html = DOMPurify.sanitize(text);
		return { __html: clean_html };
	}

	const playVideo = () => {
		setVideoOverlayStyle({ 'display': 'none' })
		setVideoSrc( require(`../assets/video/${video_src_name}`) );
		videoRef.current?.load();
		videoRef.current.play();
	}

	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.3 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {

		// is video playing when lang change?
		// pause video + reset player UI
		if (video_src_name !== null){ 
			videoRef.current.pause();
		}

		setVideoOverlayStyle({ 'display': 'flex' })

	}, [lang, video_src_name]);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM('03_video')

			// header_bold
			const header_bold = new SplitText(q(".article_header_bold"), { type: "words" })
			gsap.set(header_bold.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".header_bold"), { opacity: 1 })

			for (let i = 0; i < header_bold.words.length; i++) {
				gsap.to(header_bold.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05)
				})
			}

			// header_light
			const header_light = new SplitText(q(".article_header_light"), { type: "words" })
			gsap.set(header_light.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".header_light"), { opacity: 1 })
			for (let i = 0; i < header_light.words.length; i++) {
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.5 + (i * 0.05)
				})
			}

			// text 
			gsap.set(q(".text"), { opacity: 0, y: '+=50px' })
			gsap.to(q(".text"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 1.0
			})

			gsap.set([q(".header_bold"), q(".header_light")], { opacity: 1 }); // parent css
		}

	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<section className="video" ref={setRefs}>
				<div className="intro">
					<h3 className="article_header">
						<span className="header_bold article_header_bold">{article.header_bold}</span>
						<span className="header_light article_header_light">{article.header_light}</span>
					</h3>
					<div className="text" dangerouslySetInnerHTML={createMarkup(article.text_before_accordion)} />
				</div>

				<div className="video_container">
					<img className="img16x9" src={img16x9} alt="img16x9" width="100%" />
					
					<div className="video_overlay" style={videoOverlayStyle} onClick={playVideo}>
						<img className="poster" src={poster} alt="poster for video" width="100%" />
						<img className="play_button" src={play_btn_img} alt="Play Button" width="150" />
					</div>

					<video ref={videoRef} width="940" height="529" controls>
						<source src={video_src} type="video/mp4" />
					</video>

				</div>

		</section>
	);
}

export default Video;