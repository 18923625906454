import { useRef } from "react";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import Footer from '../Components/Footer';
import Quote from "../Components/Quote";
import ColumnsBoxes from "../Components/ColumnsBoxes";
import ColumnsText from "../Components/ColumnsText";
import CircleGraph from "../Components/CircleGraph";
import Wheel from "../Components/Wheel";
import ArticleText from '../Components/ArticleText';
import ArticleTextBg from '../Components/ArticleTextBg';
import Recommend from "../Components/Recommend";
// import ArticleInnovation from "../Components/ArticleInnovation";
import Video from "../Components/Video";
import CookieConsent from "react-cookie-consent";
import { pushGTM } from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';
import ArticleTextOneCol from "../Components/ArticleTextOneCol";

const Home = ({lang}) => {
	localStorage.setItem('lang', lang);
    i18next.changeLanguage(lang);
	localStorage.setItem('LightboxImage', 'standard_image');

	pushGTM('page_loaded_'+ lang, true)

	// menu + scrollTo
	const scrollToTop = useRef(null);
	const scrollTo1 = useRef(null), scrollToVideo = useRef(null), scrollTo2 = useRef(null), scrollTo3 = useRef(null), scrollTo4 = useRef(null), scrollTo5 = useRef(null), scrollTo6 = useRef(null), scrollTo7 = useRef(null), scrollTo8 = useRef(null), scrollTo9 = useRef(null);
	const menuItemRes = [scrollTo1, scrollToVideo, scrollTo2, scrollTo3, scrollTo4, scrollTo5, scrollTo6, scrollTo7, scrollTo8, scrollTo9];

	return (
		<div className="page">
			
			<header>
				<Header
					lang={lang}
					scrollToTop={scrollToTop}
					menuItemRes={menuItemRes}
				/>
			</header>

			<div className="content_margin_top" ref={scrollToTop}></div>
			
			<main>
				<Hero />
				
				<div className="scroll_to_me" ref={scrollTo1}></div>
				<ColumnsText textObject="double_cols_lighter" />

				<div className="scroll_to_me" ref={scrollToVideo}></div>
				<Video lang={lang} />

				<div className="scroll_to_me" ref={scrollTo2}></div>
				<ArticleTextBg textObject="article_intro" imageToRight={false} imageSize={360} bgColor="#EBF6FA" gaName={'04_intro'} />

				<div className="scroll_to_me" ref={scrollTo3}></div>

				<ArticleTextOneCol textObject="wheel_info" imageToRight={true} imageSize={false} showReadmore={true} bgColor="#fff"  />
				<Wheel lang={lang} />

				<div className="scroll_to_me" ref={scrollTo4}></div>
				<Recommend textObject="recommend" bgColor="#16A0C4" />

				<div className="scroll_to_me" ref={scrollTo5}></div>
				{/* <ArticleInnovation textObject="article_innovation" imageToRight={false} imageSize={360} bgColor="#ecf5fc" /> */}
				<ArticleTextBg textObject="article_innovation" imageToRight={true} imageSize={false} haveLightbox={true} bgColor="#ecf5fc" gaName={'08_innovation'} />

				<div className="scroll_to_me" ref={scrollTo6}></div>
				<ArticleText textObject="article_step" imageToRight={false} imageSize={false} showReadmore={true} haveLightbox={true} bgColor="#ffffff" />

				<Quote textObject="quote" bgColor="#b8b9b7" />

				<div className="scroll_to_me" ref={scrollTo7}></div>
				<ArticleTextBg textObject="article_fn" imageToRight={true} imageSize={false} showReadmore={true} haveLightbox={true} bgColor="#EBF6FA" gaName={'11_united_nations'} />

				<div className="scroll_to_me" ref={scrollTo8}></div>
				<CircleGraph />

				<div className="scroll_to_me" ref={scrollTo9}></div>
				<ColumnsBoxes textObject="vision_mission" />
			</main>

			<footer>
				<Footer scrollToTop={scrollToTop} />
        	</footer>
			<CookieConsent buttonText={ i18next.t("cookie_btn") }>{ i18next.t("cookie_bar") }</CookieConsent>
		</div> // page
	);
}
 
export default Home;