import { Link } from 'react-router-dom';

const LangSwitcher = ({lang}) => {

    let activeSE = true;
    let activeEN = false;

    if(lang === 'se'){
        activeSE = true;
        activeEN = false;
    }

    if(lang === 'en'){
        activeSE = false;
        activeEN = true;
    }

    return (
        <div className="lang_switcher">
            
            {/* development */}
           {/*  <div className={'langSwitchLink ' + (activeSE ? 'activeLangLink' : '')}>
                <Link to="/">SE</Link>
            </div>
            <span>/</span>
            <div className={'langSwitchLink ' + (activeEN ? 'activeLangLink' : '')}>
                <Link to="/en">ENG</Link>
            </div> */}

            {/* https://clients.milttonsweden.se/Lighter/test/4/ */}
			{/* https://www.lighteragenda.nu/test/1/ */}

            <div className={'langSwitchLink ' + (activeSE ? 'activeLangLink' : '')}>
                <Link to="/">SE</Link>
            </div>
            <span>/</span>
            <div className={'langSwitchLink ' + (activeEN ? 'activeLangLink' : '')}>
                <Link to="/en">ENG</Link>
            </div>

        </div>
    );
}
 
export default LangSwitcher;