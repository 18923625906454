import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import useInView from '../Functions/useInView';
import { gsap } from "gsap";
import Accordion from './Accordion';
import {SplitText} from '../assets/js/SplitText.js';
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const ColumnsBoxes = ({textObject}) => {
	const article = i18next.t(textObject, { returnObjects: true });
	const col1_image_name = article.col1_image;
	const col1_image = require(`../assets/img/${col1_image_name}`);
	const col2_image_name = article.col2_image;
	const col2_image = require(`../assets/img/${col2_image_name}`);
	const col3_image_name = article.col3_image;
	const col3_image = require(`../assets/img/${col3_image_name}`);

	// set class for gsap
 	const component = useRef(null);
	const q = gsap.utils.selector(component);
	
	// purifier
	function createMarkup(text) {
		var clean_html = DOMPurify.sanitize(text);
		return {__html: clean_html};
	} 
 
	// INVIEW
	const isInView = useInView(component);
	const [firstLoad, setFirstLoad] = useState(true);

	useEffect(() => {
		
		if(firstLoad && isInView){
			setFirstLoad(false);

			// push google analytics
			pushGTM('13_vision_mission_effect')

			// header_bold
			const header_bold = new SplitText(q(".article_header_bold"), { type: "words" })
			gsap.set(header_bold.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".header_bold"), { opacity: 1 })

			for (let i = 0; i < header_bold.words.length; i++) {
				gsap.to(header_bold.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05)
				})
			}

			// header_light
			const header_light = new SplitText(q(".article_header_light"), { type: "words" })
			gsap.set(header_light.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".header_light"), { opacity: 1 })
			for (let i = 0; i < header_light.words.length; i++) {
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.5 + (i * 0.05)
				})
			}

			// intro text
			gsap.set(q(".text_content_before"), { opacity: 0, y: '+=50px' })
			gsap.to(q(".text_content_before"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.2
			})
			
			// image
			gsap.set(q(".box_image"), {opacity: 0, x: '-=50px'})
			gsap.to(q(".box_image"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.3
			});

			// header
			const box_header = new SplitText(q(".box_header"), {type:"words"})
			gsap.set(box_header.words, {opacity: 0, x: '-=50px'})
			for(let i = 0; i < box_header.words.length; i++){
				gsap.to(box_header.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.6 + (i * 0.05	)
				})
			}

			// border
			gsap.set('.border', {opacity: 0})
				gsap.to('.border', {
					duration: 1.4,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 1.3
				})
			
			// text + accordion button
			const restElements = [q(".box_text"), q(".accordion_container")];
			gsap.set(restElements, {opacity: 0, y: '+=50px'})
			
			for(let i = 0; i < restElements.length; i++){
				gsap.to(restElements[i], {
					duration: 1.2,
					x: '0px',
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 1.3 + (i * 0.2)
				})
			}
		}
		
	}, [firstLoad, isInView, q]);
	// END INVIEW

	return (
		<section className="columns_boxes" ref={component}>
			
			<div className="intro">
				<h3 className="article_header">
					<span className="header_bold article_header_bold">{article.intro_header_bold}</span><br/>
					<span className="header_light article_header_light">{article.intro_header_light}</span>
				</h3>
				
				<div className="text_content_before" dangerouslySetInnerHTML={createMarkup(article.text_before_accordion)} />
			</div>

			<div className='grid'>

				<div className='flex flex-third box1'>
					<div className='border'></div>
					<div className='content'>
						<img className='box_image' src={col1_image} alt="Column 1" />
						<h3 className='box_header'>{article.col1_header}</h3>
						<p className='box_text' dangerouslySetInnerHTML={createMarkup(article.col1_text_before_accordion)}></p>
					</div>
				</div>

				<div className='flex flex-third box2'>
					<div className='border'></div>
					<div className='content'>
						<img className='box_image'  src={col2_image} alt="Column 2" />
						<h3 className='box_header' >{article.col2_header}</h3>
						<p className='box_text'  dangerouslySetInnerHTML={createMarkup(article.col2_text_before_accordion)}></p>
					</div>
				</div>

				<div className='flex flex-third box3'>
					<div className='border'></div>
					<div className='content'>
						<img className='box_image' src={col3_image} alt="Column 2" />
						<h3 className='box_header' >{article.col3_header}</h3>
						<p className='box_text' dangerouslySetInnerHTML={createMarkup(article.col3_text_before_accordion)}></p>
						<div className="accordion_container">
							<Accordion text={article.col3_text_after_accordion} />
						</div>
					</div>
				
				</div>
			</div>
			
		</section>
	);
}

export default ColumnsBoxes;