import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import useInView from '../Functions/useInView';
import { gsap } from "gsap";
import {SplitText} from '../assets/js/SplitText.js';
import scroll_img from '../assets/img/structure/hero_scroll_arrow.svg';
import video_src from '../assets/video/hero.mp4';
import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const Hero = () => {
 	const texts = i18next.t('hero', { returnObjects: true });
	const component = useRef(null);
	const q = gsap.utils.selector(component);

	// INVIEW
	const isInView = useInView(component);
	const [firstLoad, setFirstLoad] = useState(true);
	
	useEffect(() => {
		
		if(firstLoad && isInView){
			setFirstLoad(false);
			
			// push google analytics
			pushGTM('01_hero')
			
			// preheader
			const preheader_words = new SplitText(q(".preheader"), {type:"words"})
			gsap.set(preheader_words.words, {opacity: 0, scale: 13, x: '-=50px'});

			for(let i = 0; i < preheader_words.words.length; i++){
				gsap.to(preheader_words.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power3.out',
					delay: 0.5 + (i * 0.05	)
				})
			}

			// header
			const header_words = new SplitText(q(".header"), {type:"words"})
			gsap.set(header_words.words, {opacity: 0, x: '-=50px'})
			
			for(let i = 0; i < header_words.words.length; i++){
				gsap.to(header_words.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.9 + (i * 0.05	)
				})
			}

			// button
			gsap.set(q(".scroll_icon"), {opacity: 0, y: '-=0px', transformOrigin:"50% 0%"})

			gsap.to(q(".scroll_icon"), {
				duration: 0.0001,
				opacity: 1,
				x: '0px',
				y: '0px',
				scale: 1,
				delay: 0.99
			})

			gsap.to(q(".scroll_icon"), {
				duration: 0.5,
				x: '0px',
				opacity: 1,
				y: '40px',
				scale: 1,
				ease: 'sine.in-out',
				yoyo: true,
				repeat: -1,
				delay: 1.0
			})
		}
		
	}, [firstLoad, isInView, q]);
	// END INVIEW

	return (
		<section className="hero" ref={component} id="hero">
			<img className="scroll_icon" src={scroll_img} alt="scroll icon" />

			<div className="video_container">
				<div className="headers_container">
					<h1 className="headers">
						<span className="preheader">{texts.preheader}</span>
						<span className="header">{texts.header}</span>
						<span className="header header2">{texts.header2}</span>
					</h1>
				</div>

				<div className="video_inner">
					<div className="video_overlay"></div>
					<video id="hero_video" autoPlay loop playsInline muted>
						<source src={video_src} type="video/mp4" />
					</video>
				</div>
			</div>
		</section>
	);
}

export default Hero;