import { gsap } from "gsap";
import { useRef } from "react";
import LangSwitcher from '../Components/LangSwitcher';
import LighterLogo from '../assets/img/LIGHTer_logo.svg';
import { useEffect } from 'react';

import i18next, { t } from 'i18next';
import '../i18n';

const Header = ({ lang, scrollToTop, menuItemRes }) => {
	// hamburger
	let open = false;
	const header = useRef(null);
	const hamburger = useRef(null);
	const menu = useRef(null);
	const menu_agenda_name = useRef(null);
	
	// hamburger click	
	const itemClick = () => {
		// open
		if (open === false) {
			open = true;
			hamburger.current.className = "mobile_menu_open";

			gsap.to(menu.current, {
				duration: 0.5,
				ease: 'sine.in',
				height: '100%'
			});

		// close
		} else {
			open = false;
			hamburger.current.className = "mobile_menu";

			gsap.to(menu.current, {
				duration: 0.5,
				ease: 'sine.out',
				height: '0px'
			});
		}

	}
	// end hamburger click

	// menuItems click
	const menu_items_text = i18next.t('menu', { returnObjects: true });
	const scrollToItem = (i) => {
		menuItemRes[i].current?.scrollIntoView({ behavior: 'smooth' });
		
		// close menu
		open = false;
		hamburger.current.className = "mobile_menu";
		gsap.to(menu.current, {
			duration: 0.5,
			ease: 'sine.out',
			height: '0px'
		});
	}

	// logo click
	const logoClick = () => {
		scrollToTop.current.scrollIntoView({ behavior: "smooth" });

		// close menu
		open = false;
		hamburger.current.className = "mobile_menu";
		gsap.to(menu.current, {
			duration: 0.5,
			ease: 'sine.out',
			height: '0px'
		});
	}

	useEffect(() => {
		window.addEventListener("scroll", calc_hero);
	});

	function calc_hero(){
		const hero = document.getElementById('hero');
		let bottom = hero.getBoundingClientRect().bottom;
		
		if(bottom > header.current.clientHeight){
			menu_agenda_name.current.classList.remove('menu_agenda_name_show')
		}else{
			menu_agenda_name.current.classList.add('menu_agenda_name_show')
		}
	}

	return (
		<div className="header" ref={header}>
			<div className="menu_container">
				<div className="menu_modules">
					
					<div className="menuItemLogo" onClick={logoClick}>
						<img src={LighterLogo} alt="Logo" />
					</div>
					
					<div className="menu_agenda_name" ref={menu_agenda_name}>
						<p>{t('menu_agenda_name')}</p>
					</div>

					<div className="menu_modules_right">
						<LangSwitcher lang={lang} />
						<div className="mobile_menu" ref={hamburger} onClick={itemClick}>
						</div>
					</div>

				</div>
				
				<div className="menu_bar" ref={menu}>
					<nav>
						{
							// loop menu items
							menuItemRes.map((text, i) => <div className="menu_items" key={i} onClick={() => scrollToItem(i)}>{menu_items_text[i]}</div>)
						}
						
						<LangSwitcher lang={lang} />
					</nav>
				</div>

			</div>
		</div>

	);
};

export default Header;