import { Parallax, Background } from 'react-parallax';
import i18next from 'i18next';
import '../i18n';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { gsap } from "gsap";
import {pushGTM} from '../Functions/useGTM';

const Quote = ({ textObject, bgColor}) => {
	const ref = useRef();
	const q = gsap.utils.selector(ref);

	const quote = i18next.t(textObject, { returnObjects: true });
	const image_bg = quote.image_bg;
	const bg = require(`../assets/img/bgs/${image_bg}`); // this is sync

	const image_person = quote.image_person;
	const person = require(`../assets/img/article/${image_person}`); // this is sync

	const quote_text = quote.quote_text;
	const person_name = quote.person_name;
	
	// background color
	const bgColorCss = { 'backgroundColor': bgColor }


	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM('10_quote')

			gsap.set(q(".quote_person_img"), { opacity: 0, x: '-=50px' })
			gsap.to(q(".quote_person_img"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.0
			});
			
			gsap.set(q(".blockquote"), { opacity: 0, y: '+=50px' })
			gsap.to(q(".blockquote"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.2
			});

			gsap.set(q(".cite"), { opacity: 0, y: '+=50px' })
			gsap.to(q(".cite"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.4
			});



			// gsap.set([q(".header_bold"), q(".header_light")], { opacity: 1 }); // parent css

		}

	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<div ref={setRefs} className="quote_parallax_bg" style={bgColorCss}>
			<Parallax strength={400}>
	  			<Background>
					<img src={bg} alt="The backgound" />
				</Background>
				
				<div className="parallax_content">
					<img className="quote_person_img" src={person} alt="Person" />
					<blockquote className="blockquote">
					{quote_text}
					<cite className="cite">{person_name}</cite>
					</blockquote>
				</div>
			</Parallax>

		</div>
	);
}
 
export default Quote;