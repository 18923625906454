import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import common_se from './Translations/se.json';
import common_en from './Translations/en.json';

const resources = {
    se: {
        translation: common_se
    },
    en: {
        translation: common_en
    }
}

i18n
.use(initReactI18next)
.init({
    resources,
    lng : 'se',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;