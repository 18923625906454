import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useRef} from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import Accordion from './Accordion';
import {SplitText} from '../assets/js/SplitText.js';
import { Parallax, Background } from 'react-parallax';

import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import {pushGTM} from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const ArticleTextBg = ({ textObject, imageToRight, imageSize, bgColor, haveLightbox, gaName}) => {
	const article = i18next.t(textObject, { returnObjects: true });
	const img_name = article.image_name;
	const picture = require(`../assets/img/article/${img_name}`); // this is sync

	// image size
	let image_css = { 'maxWidth': 'auto' }
	let small_image_class = '';
	
	if(imageSize !== false){
		small_image_class = 'small_image';

		if(imageToRight === false){
			image_css = {'maxWidth': imageSize + 'px', 'float': 'right'}
		}else{
			image_css = {'maxWidth': imageSize + 'px'}
		}
	}

	// lightbox
	const lightbox_section_ref = useRef();
	const lightbox_section_image_ref = useRef();
	const lightbox_close_ref = useRef();
	
	let lightbox_image_small_src;
	let lightbox_image_large_src;
	
	if (haveLightbox) {
		const lightbox_img_name = article.lightbox_small;
		lightbox_image_small_src = require(`../assets/img/article/${lightbox_img_name}`);
	}

	const openLightbox = () => {
		let current_image = localStorage.getItem('LightboxImage');
		let lightbox_large_img_name = article.lightbox_large;
		lightbox_image_small_src = require(`../assets/img/article/${lightbox_large_img_name}`);
		
		
		if (current_image === 'standard_image' || current_image !== lightbox_image_small_src){
			localStorage.setItem('LightboxImage', lightbox_image_small_src);
			lightbox_section_image_ref.current.src = lightbox_image_small_src;
		}


		gsap.set(lightbox_section_ref.current, { opacity: 0 })
		lightbox_section_ref.current.classList.remove("hide_lightbox");
		lightbox_section_ref.current.classList.add("show_lightbox");

		gsap.to(lightbox_section_ref.current, {
			duration: 1,
			opacity: 1,
			x: '0px',
			y: '0px',
			scale: 1,
			ease: 'power2.out',
			delay: 0
		})
	}

	const closeLightbox = () => {
		gsap.to(lightbox_section_ref.current, {
			duration: 1,
			opacity: 0,
			x: '0px',
			y: '0px',
			scale: 1,
			ease: 'power2.out',
			delay: 0,
			onComplete: tweenComplete
		})
	}
	
	const tweenComplete = () => {
		lightbox_section_ref.current.classList.add("hide_lightbox");
		lightbox_section_ref.current.classList.remove("show_lightbox");
	}

	// end lightbox

	// background color
	const bgColorCss = { 'backgroundColor': bgColor }

	// bgparallax
	let bgparallax;
	if(article.bg_prallax_image !== undefined){
		const parallax_image = article.bg_prallax_image;
		bgparallax = require(`../assets/img/bgs/${parallax_image}`);
	}
	
	const ref = useRef();
	const q = gsap.utils.selector(ref);

	// purifier
	function createMarkup(text) {
		var clean_html = DOMPurify.sanitize(text);
		return {__html: clean_html};
	}

	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM(gaName)

			// header_bold
			const header_bold = new SplitText(q(".article_header_bold"), { type: "words" })
			gsap.set(header_bold.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".header_bold"), { opacity: 1 })

			for (let i = 0; i < header_bold.words.length; i++) {
				gsap.to(header_bold.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.3 + (i * 0.05)
				})
			}

			// header_light
			const header_light = new SplitText(q(".article_header_light"), { type: "words" })
			gsap.set(header_light.words, { opacity: 0, x: '-=50px' })
			gsap.set(q(".header_light"), { opacity: 1 })
			for (let i = 0; i < header_light.words.length; i++) {
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0px',
					y: '0px',
					scale: 1,
					ease: 'power2.out',
					delay: 0.5 + (i * 0.05)
				})
			}

			// text + accordion button
			const restElements = [q(".text_content_before"), q(".accordion_container")];
			gsap.set(restElements, { opacity: 0, y: '+=50px' })

			for (let i = 0; i < restElements.length; i++) {
				gsap.to(restElements[i], {
					duration: 1.2,
					x: '0px',
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 1.0 + (i * 0.2)
				})
			}

			// image
			gsap.set(q(".article_image"), { opacity: 0, x: '-=50px' })
			gsap.to(q(".article_image"), {
				duration: 1.2,
				x: '0px',
				y: '0px',
				opacity: 1,
				ease: 'power2.out',
				delay: 0.8
			});

			gsap.set([q(".header_bold"), q(".header_light")], { opacity: 1 }); // parent css

		}

	}, [firstLoad, inView, q, gaName]);
	// END INVIEW

	return (

		<div className={textObject}>
			{haveLightbox &&
				<div>
					<div className="lightbox_section hide_lightbox" ref={lightbox_section_ref}>
						
						<div className="content">
						<div className="lightbox_close" ref={lightbox_close_ref} onClick={closeLightbox}><span className="close_text">{i18next.t('lightbox_close')}</span><span className="close_icon"></span></div>
						
							<div className="">
							</div>
							
							<img
								className="lightbox_image"
								src={lightbox_image_large_src}
								alt="Lightbox Graphics"
								ref={lightbox_section_image_ref}
							/>
						</div>

						<div className="lightbox_cover"></div>
						<div className="">
						</div>
					</div>
				</div>
			}

			<section ref={setRefs} className="article_container article_bg_container" style={bgColorCss}>
				<Parallax strength={200}>

				<div className="article_inner">

						<div className={`flex ${small_image_class}` + (imageToRight ? ' flexReverse' : '') }>
						<div className="image_content" style={image_css}>

							{/* lightbox/standard */}
							{haveLightbox ?
								<div className="article_image lightbox_small_image">
										<img
											src={lightbox_image_small_src}
											alt="Article Graphics"
											onClick={openLightbox}
										/>
										<span className="lightbox_description">{i18next.t('lightbox_description') }</span>
								</div>
								: 
								<img src={picture} className="article_image" alt="Article Graphics" style={image_css} />
							}
							{/* end lightbox/standard */}
						</div>

						<div className="text_content">
							<h3 className="article_header">
								<span className="header_bold article_header_bold">{article.header_bold}</span><br/>
								<span className="header_light article_header_light">{article.header_light}</span>
							</h3>

							<div className="text_content_before" dangerouslySetInnerHTML={createMarkup(article.text_before_accordion)} />
							<div className="accordion_container">
								<Accordion text={article.text_after_accordion} />
							</div>

						</div>
					</div>

				
				</div>

				<Background>

					{bgparallax && 
						<img src={bgparallax} alt="En bakgrundsbild" />
					}
				</Background>
				</Parallax>
			</section>
		</div>

	);
}

export default ArticleTextBg;